html.dark {
    --general-color: #fff;
    --general-title-color: var(--general-color);
    --general-subtitle-color: var(--color-gray-2);
    --general-background: #1C1A1C;

    --page-title-color: var(--general-title-color);
    --page-subtitle-color: var(--general-subtitle-color);

    --select-box-color: var(--color-blue-2);

    --circle-button-color: var(--color-gray-2);
    --circle-button-background-color: #282828;

    --hline-color: #282828;

    --link-color: var(--color-blue-2);

    --drawer-title-color: var(--general-color);

    --backdrop-color: rgba(40, 40, 40, .7);

    --main-menu-item-color: var(--general-color);
    --main-menu-selected-icon-color: var(--link-color);

    --summary-loader-background-color: #282828;
    --summary-loader-placeholder-color: #242324;

    --list-item-color: var(--general-subtitle-color);
    --list-item-selected-icon-color: var(--link-color);

    --text-input-border-color: #282728;

    --chart-background: var(--general-background);
    --chart-marker-fill-color: var(--general-background);
    --chart-grid-line-color: var(--hline-color);
    --chart-loader-background-color: #282828;
    --chart-loader-icon-color: #242324;
    --chart-no-data-color: #282828;
    --chart-line-color: #fff;


    --card-label-color: var(--color-gray-2);
    --card-value-color: #fff;
    --card-odd-row-background: var(--general-background);
    --card-even-row-background: rgba(40, 40, 40, .5);
    --card-loader-background-color: #282828;
    --card-header-background-color: var(--general-background);

    --datepicker-disabled-option: #282828;

    --insight-card-negative-value-color: #fff;

    background-color: var(--general-background);
}
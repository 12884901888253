@import "fonts";
@import "colors";
@import "variables";

html {
    font-size: 62.5%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    body {
        flex: 1;
        font-size: 100%;
        margin: 0;
        font-size: 1.6rem;
        font-weight: 400;
        font-family: var(--font-primary);
        display: flex;
        flex-direction: column;
        color: var(--general-color, #000);
    }

    #root {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: var(--general-background, #fff);
    }

    * {
        box-sizing: inherit;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
}